import Vue from 'vue'

import Cookies from 'js-cookie'
// import 'normalize.css/normalize.css' // a modern alternative to CSS resets

import Element from 'element-ui'

import enLang from 'element-ui/lib/locale/lang/en'
import '@/styles/main.scss' // global css

import App from './App'
import store from './store'
import router from './router'

import './permission' // permission control
import DlgDraggable from "vue-element-dialog-draggable"
import VAnimateCss from 'v-animate-css';
import VueConfetti from 'vue-confetti'

Vue.use(VueConfetti)
Vue.use(VAnimateCss);

Vue.use(DlgDraggable, {
  containment: true //Constrains dragging to within the bounds of the window.  Default: false.
});

Vue.use(Element, {
  size: Cookies.get('size') || 'medium',
  locale: enLang
})

Vue.config.productionTip = false

new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App)
})
