import axios from 'axios'
import { Message } from 'element-ui'
import store from '@/store'
import { cacheAdapterEnhancer } from 'axios-extensions';
import { decamelizeKeys } from 'humps';



// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API_URL, // url = base url + request url
  adapter: cacheAdapterEnhancer(axios.defaults.adapter),
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 35000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
      const newConfig = { ...config };

      if (store.getters.token) {
          newConfig.headers['Authorization'] = `Bearer ${store.getters.token}`
      }

      if (config.params) {
          newConfig.params = decamelizeKeys(config.params, {
              process: function (key, convert, options) {
                  //assert only lowerCamelCase
                  return /(?<![_.])[A-Z0-9]+(?=[a-z])/.test(key) && !key.includes('.') && !key.includes('_') ? convert(key, options) : key;
              } });
      }

      if (config.data) {
          newConfig.data = decamelizeKeys(config.data);
      }

      return newConfig
  },
  error => {
    // do something with request error
    console.error(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  response => {
    const res = response


    if (![200, 201, 204].includes(res.status)) {
        Message({
        message: res.data || `${res.status} Error`,
        type: 'error',
        duration: 5 * 1000
      })
    }
    return res

  },
  error => {
    console.error(error)
    if (error.response){
      if (error.response.status === 401) {
          if (store.getters.token) {
              store.dispatch('user/logout').then(() => {
                  location.reload()
              })
          }
          return Promise.reject('Authorization Error')
      }
      return Promise.reject(new Error(`${error.response.status} (${error.response.statusText}) - ${JSON.stringify(error.response.data) || 'API ERROR'}`))
    } else {
      Message({
          message: "Server connection error",
          type: 'error',
          duration: 5 * 1000
      })
    }

    return Promise.reject(error)
  }
)

export default service
