import request from '@/utils/request'

export function login(data) {
  return request({
    url: '/auth/login/',
    method: 'post',
    data
  })
}

export function loginWithCode(data) {
  return request({
    url: '/auth/login/code/',
    method: 'post',
    data
  })
}
export function getAppCode() {
  return request({
    url: '/auth/app/activate/',
    method: 'post',
  }).then(r => r.data.qr_link)
}

export function confirmAppCode(data) {
  return request({
    url: '/auth/app/activate/confirm/',
    method: 'post',
    data
  }).then(r => r.data)
}

export function revokeMfa(userId) {
  return request({
    url: `/v1/users/${userId}/revoke_mfa/`,
    method: 'post',
  });
}

export function getInfo(userId) {
  return request({
    url: `/v1/users/${userId}/`,
    method: 'get',
  })
}

export function updateInfo(userId, data) {
  return request({
    url: `/v1/users/${userId}/`,
    method: 'patch',
    data
  })
}

export function createUser(data) {
  return request({
    url: `/v1/users/`,
    method: 'post',
    data
  })
}

export function deleteUser(userId) {
  return request({
    url: `/v1/users/${userId}/`,
    method: 'delete',
  })
}

export function listUsers(page, query) {
  const params = {};
  if (page) {
    params.page = page;
  }
  if (query) {
    params.search = query;
  }
  return request({
    url: `/v1/users/`,
    method: 'get',
    params,
    cache: false
  })
}

export function listGroups() {
  return request({
    url: `/v1/groups/`,
    method: 'get',
  })
}


export function logout() {
  // TODO: make logout route to invalidate token
  return request({
    url: `logout/`,
    method: 'get',
  })
}
