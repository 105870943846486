import {logout, getInfo, updateInfo} from '@/api/user'
import { resetRouter } from '@/router'
import VueJwtDecode from 'vue-jwt-decode'

// import store from "@/store";

const state = {
  token: '',
  refresh: '',
  expiry: 0,
  name: '',
  avatar: '',
  ID: '',
  roles: []
}

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_REFRESH: (state, token) => {
    state.refresh = token
  },
  SET_EXPIRY: (state, expiry) => {
    state.expiry = expiry
  },
  SET_ID: (state, ID) => {
    state.ID = ID
  },
  SET_NAME: (state, name) => {
    state.name = name
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar
  },
  SET_ROLES: (state, roles) => {
    state.roles = roles
  }
}

const actions = {
  saveToken({commit}, tokenPair) {
    return new Promise((resolve) => {
      const {access, refresh} = tokenPair
      const decoded = VueJwtDecode.decode(access)
      commit('SET_EXPIRY', decoded.exp)
      commit('SET_REFRESH', refresh)
      commit('SET_TOKEN', access)
      commit('SET_ID', decoded.user_id)

      resolve()
    })
  },
  updateUser({ commit, state }, data) {

    updateInfo(state.ID, data);

    return new Promise((resolve) => {

      const { firstName, lastName, picture } = data;
      commit('SET_NAME', `${firstName} ${lastName}`);
      commit('SET_AVATAR', picture);

      resolve(data)

    console.log("store")
    console.log(state.name)
    })
  },
  // get user info
  getInfo({ commit, state, dispatch }) {
    return new Promise((resolve, reject) => {
      getInfo(state.ID).then(response => {
        const { data } = response

        if (!data) {
          reject('Verification failed, please Login again.')
        }

        const { first_name, last_name, groups, picture, features } = data
        dispatch('features/setFeatures', features, { root:true })

        commit('SET_NAME', `${first_name} ${last_name}`)
        commit('SET_AVATAR', picture)
        commit('SET_ROLES', [groups.name])

        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },
  // user logout
  logout({ commit, state, dispatch }) {
    return new Promise((resolve) => {
      commit('SET_TOKEN', '')
      commit('SET_EXPIRY', 0)
      commit('SET_REFRESH', '')
      commit('SET_ROLES', [])
      commit('SET_NAME', '')

      resetRouter()
      dispatch('workList/resetWorkList', null, { root: true })
      dispatch('features/clearFeatures', null, { root: true })
      dispatch('misc/setProductivitySelection', '', {root: true})

      logout(state.token)

      resolve()
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
