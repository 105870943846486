const state = {
    activeWorkListView: ['all'],
    activeProvider: '',
    activeQueue: [],
    todoList: [],
    activeSideBarCollapse: null,
    filters: {}
}

const mutations = {
    SET_ACTIVE_WORK_LIST_VIEW: (state, activeWorkListView) => {
        state.activeWorkListView = activeWorkListView
    },
    SET_ACTIVE_PROVIDER: (state, activeProvider) => {
        state.activeProvider = activeProvider
    },
    SET_ACTIVE_QUEUE: (state, activeQueue) => {
        state.activeQueue = activeQueue
    },
    SET_ACTIVE_FILTERS: (state, filters) => {
        state.filters = filters
    },
    SET_TODO_LIST: (state, accounts) => {
        state.todoList = accounts
    },
    SET_ACTIVE_SIDE_BAR_COLLAPSE: (state, activeSideBarCollapse) => {
        state.activeSideBarCollapse = activeSideBarCollapse
    }
}

const actions = {
    resetWorkList({ commit }) {
        return new Promise(resolve => {
            commit('SET_ACTIVE_WORK_LIST_VIEW', ['all'])
            commit('SET_ACTIVE_PROVIDER', '')
            commit('SET_ACTIVE_QUEUE', '')
            commit('SET_ACTIVE_FILTERS', {})

            resolve()
        })
    },

    updateItemsToResolve({ commit }, { payload }) {
        return new Promise(resolve => {
            commit('SET_TODO_LIST', payload)
            resolve()
        })
    },

    setFilter({commit}, { queue, payload }) {
        return new Promise(resolve => {
            const filters = state.filters
            filters[queue] = payload
            commit('SET_ACTIVE_FILTERS', filters)
            resolve()
        })
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
