const state = {
    features: [],
    lastVersion: null
}

const mutations = {
    SET_FEATURES: (state, features) => {
        state.features.push(features)
    },
    CLEAR_FEATURES: (state) => {
        state.features = []
    },
    SET_LAST_VERSION: (state, version) => {
        state.lastVersion = version
    }
}

const actions = {
    setFeatures({ commit }, features) {
      for(let [feature, enabled] of Object.entries(features)) {
          if (enabled) commit('SET_FEATURES', feature)
      }
    },

    clearFeatures({ commit}) {
        commit('CLEAR_FEATURES')
    },

    setLastVersion({commit}, version) {
        commit('SET_LAST_VERSION', version)
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
