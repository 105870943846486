import router from './router'
import store from './store'
// import { Message }s from 'element-ui'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
import getPageTitle from '@/utils/get-page-title'

NProgress.configure({ showSpinner: false }) // NProgress Configuration

const whiteList = ['/login', '/auth-redirect'] // no redirect whitelist

router.beforeEach(async(to, from, next) => {
  // start progress bar
  NProgress.start()

  // set page title
  document.title = getPageTitle(to.meta.title)

  // determine whether the user has logged in
  const isLoggedIn = await store.getters?.token && await store.getters.roles[0]
  const exp = await store.getters.expiry

  if (exp < new Date().getTime() / 1000 && exp !== 0) {
    console.log("expired")
    store.dispatch('user/logout').then(() => {
      next({path: '/login'})
    })
    NProgress.done()
  } else if (isLoggedIn) {
    if (to.path === '/login') {
      // if is logged in, redirect to the home page
      next({ path: '/' })
      NProgress.done()
    } else {
      if (to.meta.protected) {
        if (to.meta.roles.includes(store.getters.roles[0])) {
          next()
        } else {
          // does not have access to page
          next({path: '/401'})
        }
      } else {
        next()
      }
      NProgress.done()
    }
  } else {
    if (whiteList.indexOf(to.path) !== -1) {
      // in the free login whitelist, go directly
      next()
    } else {
      // other pages that do not have permission to access are redirected to the login page.
      next(`/login?redirect=${to.path}`)
      NProgress.done()
    }
  }
})

router.afterEach(() => {
  // finish progress bar
  NProgress.done()
})
