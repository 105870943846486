<template>
  <div class="sidebar">
    <img
      height="40px"
      width="40px"
      src="@/assets/icons/CRH-Icon.svg"
      alt=""
      class="crossfire-icon"
    >
    <div
      v-for="route in routes"
      :key="route.path"
      class="nav"
    >
      <router-link :to="{ name: route.name}">
        <img
          v-if="$route.name === route.name"
          height="32px"
          width="32px"
          :alt="route.name"
          :src="getImgUrl(route.activeIcon)"
        >
        <img
          v-else
          height="32px"
          width="32px"
          :alt="route.name"
          :src="getImgUrl(route.icon)"
        >
      </router-link>
    </div>
    <div
      class="easter-egg"
      @click="eggy"
    />

    <div
      ref="splash"
      class="hidden"
    >
      <img
        v-animate-css="{
          classes: 'wobble',
          duration: 5000,
          iteration: 'infinite'
        }"
        src="https://i1.wp.com/www.jeopardy.com/sites/default/files/styles/contestant_home/public/contestant-photos/wk13_1203_1207_leffler_home.png?resize=355%2C591&ssl=1"
        alt=""
      >
    </div>
  </div>
</template>

<script>
import store from "@/store";

export default {
  name: 'Sidebar',
  data () {
    return {
      routes: [],
      eggCount: 0
    }
  },
  created() {
    this.$router.options.routes.forEach(route => {
      if (!route.hidden) {
        route.children.forEach( child => {
          if (!child.meta.protected || (child.meta.protected && child.meta.roles.includes(store.getters.roles[0]))) {
            if (!child.hidden){
              this.routes.push({
                name: child.name,
                title: child.meta.title,
                path: `${route.path}/${child.path}`,
                icon: child.meta.icon,
                activeIcon: child.meta.activeIcon,
              })
            }
          }
        })

      }
    })
  },
  methods: {
    getImgUrl(img) {
      const icon = require.context('@/assets/icons', false, /\.svg$/)
      return icon(`./icon-${img}-default.svg`)
    },
    eggy() {
      if(++this.eggCount === 10) {
        this.$refs.splash.classList.toggle('hidden')
        window.setTimeout(() => this.$refs.splash.classList.toggle('hidden'), 3000)
      }
    }
  }
}
</script>
<style scoped lang="scss">
.sidebar {
  background: #23252E;
  height: 100vh;
  margin: 0;
  padding-top: 10px;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  .crossfire-icon {
    margin-bottom: 20px;
  }

  .nav {
    margin-top: 15px;
  }

  .easter-egg {
    height: 1px;
    width: 1px;
    position: fixed;
    bottom: 0;
    left: 0;
  }

  .hidden {
    display: none;
  }
}
</style>
