<template>
  <div class="navbar">
    <el-row :gutter="10">
      <el-col
        :span="6"
        :offset="0"
      >
        <div class="title">
          {{ currentRouteName }}
        </div>
      </el-col>
      <el-col
        :span="2"
        :offset="16"
      >
        <el-dropdown
          class="profile-link"
          trigger="click"
        >
          <span class="el-dropdown-link">
            <el-avatar
              size="small"
              :src="avatar"
              fit="scale-down"
            />
            <i class="el-icon-caret-bottom el-icon--right" />
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              class="clearfix"
              @click.native="profile"
            >
              <el-link icon="el-icon-edit">
                Profile
              </el-link>
            </el-dropdown-item>
            <el-dropdown-item
              class="clearfix"
              @click.native="logout"
            >
              <el-link icon="el-icon-close">
                Sign out
              </el-link>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: "NavBar",
  computed: {
    ...mapGetters([
      'roles',
      'avatar'
    ]),
    currentRouteName() {
      return this.$route.meta.title;
    }
  },
  methods: {
    async logout() {
      await this.$store.dispatch('user/logout')
      await this.$router.push(`/login?redirect=/`)
    },
    profile () {
      this.$router.push('/profile/edit')
    }
  }
}
</script>

<style lang="scss">
.el-header {
  background: #FFF;
  border-bottom: 1px solid #EBEEF5;
  padding: 0;
  width: calc(100vw - 45px); // calc from width of aside

  .navbar {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;

    .title {
      margin-top: 5px;
      margin-left: 25px;
      font-size: 1.3em;
    }

    .el-dropdown-link {
      border: 2px solid lightgrey;
      padding: 0px 0px 0px 10px;
      border-radius: 10vh;

      display: flex;
      justify-content: center;
      align-items: center;

      .el-avatar {
        height: 25px;
        width: 25px;
      }
      .el-icon-caret-bottom {
        margin: 10px;
      }
    }
  }


}
</style>
