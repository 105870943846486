const state = {
    productivitySelection: ''
}

const mutations = {
    SET_PRODUCTIVITY_SELECTION: (state, selection) => {
        state.productivitySelection = selection
    }
}

const actions = {
    setProductivitySelection({commit}, selection) {
        commit('SET_PRODUCTIVITY_SELECTION', selection)
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
