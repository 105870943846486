

const state = {
    isCollapse: false
}

const mutations = {
    TOGGLE_SIDEBAR: (state) => {
        state.isCollapse = !state.isCollapse
    }
}

export default {
    namespaced: true,
    state,
    mutations
}
