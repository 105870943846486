<template>
  <el-container class="app-container">
    <el-aside width="54px">
      <sidebar class="sidebar-container" />
    </el-aside>
    <el-container>
      <el-header
        class="header-container"
        height="65px"
      >
        <navbar />
      </el-header>
      <el-main class="main-app-container">
        <app-main />
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import { AppMain, Sidebar, Navbar} from './components'
import { mapState } from 'vuex'

export default {
  name: 'LayOut',
  components: {
    AppMain,
    Sidebar,
    Navbar
  },
  computed: {
    ...mapState({
      sidebar: state => state.app.sidebar,
      device: state => state.app.device,
      showSettings: state => state.settings.showSettings,
      needTagsView: state => state.settings.tagsView,
      fixedHeader: state => state.settings.fixedHeader
    }),
    classObj() {
      return {
        hideSidebar: !this.sidebar.opened,
        openSidebar: this.sidebar.opened,
        withoutAnimation: this.sidebar.withoutAnimation,
        mobile: this.device === 'mobile'
      }
    }
  },
}
</script>

<style>
.app-container{
  position: fixed;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
}
.el-main main {
  padding: 0;
}
.main-app-container {
  overflow: hidden;
}
.header-container, .main-app-container {
  padding: 0;
  margin: 0;
}
</style>
